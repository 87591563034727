var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-overview" },
    [
      _c("div"),
      _c(
        "div",
        { staticClass: "refresh-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.dataDialogVisible = true
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-question",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("统计说明")
            ]
          ),
          _c("div", { staticStyle: { margin: "0 20px" } }, [
            _vm._v("更新时间：" + _vm._s(_vm.time))
          ]),
          _c(
            "el-button",
            {
              staticClass: "refresh-btn",
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.refresh()
                }
              }
            },
            [
              _c("i", {
                staticClass: "el-icon-refresh",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("刷新")
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "module" }, [
        _c("div", [
          _c(
            "div",
            {
              staticClass: "module-content",
              staticStyle: { "margin-bottom": "30px" }
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("用户统计")]),
              _c("div", { staticClass: "count-content" }, [
                _c("div", { staticClass: "count-num" }, [
                  _c("div", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.residingUserCount))
                  ]),
                  _c("div", [_vm._v("在住用户")])
                ]),
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "count-num" }, [
                  _c("div", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.vacatedUserCount))
                  ]),
                  _c("div", [_vm._v("已退租用户")])
                ]),
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "count-num" }, [
                  _c("div", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.sharingAuthorizedUserCount))
                  ]),
                  _c("div", [_vm._v("分享授权用户")])
                ]),
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "count-num" }, [
                  _c("div", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.smartLockUserCount))
                  ]),
                  _c("div", [_vm._v("智家锁用户")])
                ])
              ]),
              _c("div", { staticClass: "line-x" }),
              _c(
                "div",
                {
                  staticClass: "count-content",
                  staticStyle: { "padding-bottom": "10px" }
                },
                [
                  _c("div", { staticClass: "count-num" }, [
                    _c("div", { staticStyle: { "font-size": "30px" } }, [
                      _vm._v(_vm._s(_vm.verifiedUserCount))
                    ]),
                    _c("div", [_vm._v("实名认证用户")])
                  ]),
                  _c("div", { staticClass: "line" }),
                  _c("div", { staticClass: "count-num" }, [
                    _c("div", { staticStyle: { "font-size": "30px" } }, [
                      _vm._v(_vm._s(_vm.ordinaryUserCount))
                    ]),
                    _c("div", [_vm._v("普通用户(手机号码)")])
                  ]),
                  _c("div", { staticClass: "line" }),
                  _c("div", { staticClass: "count-num" }, [
                    _c("div", { staticStyle: { "font-size": "30px" } }, [
                      _vm._v(_vm._s(_vm.pmsAllocatedUserCount))
                    ]),
                    _c("div", [_vm._v("PMS排房用户")])
                  ]),
                  _c("div", { staticClass: "line" }),
                  _c("div", { staticClass: "count-num" }, [
                    _c("div", { staticStyle: { "font-size": "30px" } }, [
                      _vm._v(_vm._s(_vm.deactivatedUserCount))
                    ]),
                    _c("div", [_vm._v("注销用户")])
                  ])
                ]
              )
            ]
          ),
          _c("div", { staticClass: "module-content" }, [
            _c("div", { staticClass: "title" }, [_vm._v("房东统计")]),
            _c("div", { staticClass: "count-content2" }, [
              _c("div", { staticClass: "module-count count1" }, [
                _c("div", { staticStyle: { "text-align": "left" } }, [
                  _c("div", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.approvedLandlordCount))
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.approvedLandlordPercent) + "%")
                  ]),
                  _c("div", [_vm._v("房东(已审核通过)")])
                ]),
                _c("img", {
                  staticStyle: {
                    width: "53px",
                    height: "51px",
                    "margin-top": "35px"
                  },
                  attrs: { src: require("../../assets/fangdong (1).png") }
                })
              ]),
              _c("div", { staticClass: "module-count count2" }, [
                _c("div", { staticStyle: { "text-align": "left" } }, [
                  _c("div", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.corporateLandlordCount))
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.corporateLandlordPercent) + "%")
                  ]),
                  _c("div", [_vm._v("企业房东")])
                ]),
                _c("img", {
                  staticStyle: {
                    width: "43px",
                    height: "44px",
                    "margin-top": "35px"
                  },
                  attrs: { src: require("../../assets/qiyefangdong.png") }
                })
              ]),
              _c("div", { staticClass: "module-count count3" }, [
                _c("div", { staticStyle: { "text-align": "left" } }, [
                  _c("div", { staticStyle: { "font-size": "30px" } }, [
                    _vm._v(_vm._s(_vm.activeLandlordCount))
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.activeLandlordPercent) + "%")]),
                  _c("div", [_vm._v("房东（在用/有租约）")])
                ]),
                _c("img", {
                  staticStyle: {
                    width: "60px",
                    height: "40px",
                    "margin-top": "35px"
                  },
                  attrs: { src: require("../../assets/ziyongfangdong.png") }
                })
              ])
            ])
          ])
        ]),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("用户分布top10")]),
            _c("div", {
              staticClass: "module-content",
              style: { width: "784px", height: "322px" },
              attrs: { id: "user-echart" }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          {
            staticStyle: { background: "#FFFFFF", padding: "10px 10px 0 10px" }
          },
          [
            _c(
              "div",
              {
                staticClass: "title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v("用户画像_性别")]
            ),
            _c("div", {
              style: { width: "534px", height: "322px" },
              attrs: { id: "sex-pie" }
            })
          ]
        ),
        _c(
          "div",
          {
            staticStyle: { background: "#FFFFFF", padding: "10px 10px 0 10px" }
          },
          [
            _c(
              "div",
              {
                staticClass: "title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v("用户增长数据")]
            ),
            _c(
              "div",
              {
                staticStyle: { "text-align": "right", "margin-right": "56px" }
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeYear()
                      }
                    }
                  },
                  [_vm._v("按年")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeMonth()
                      }
                    }
                  },
                  [_vm._v("按月")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { height: "30px", "line-height": "0px" },
                    on: {
                      click: function($event) {
                        _vm.changeDay()
                      }
                    }
                  },
                  [_vm._v("按日")]
                )
              ],
              1
            ),
            _c("div", {
              style: { width: "1034px", height: "322px" },
              attrs: { id: "line-echart" }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "module" }, [
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("在住分析")]),
            _c("div", {
              style: { width: "1004px", height: "322px" },
              attrs: { id: "live-echart" }
            })
          ]
        ),
        _c(
          "div",
          { staticStyle: { background: "#FFFFFF", padding: "10px 0 0 10px" } },
          [
            _c(
              "div",
              {
                staticClass: "title",
                staticStyle: { "margin-bottom": "10px" }
              },
              [_vm._v("用户画像_年龄")]
            ),
            _c("div", {
              style: { width: "586px", height: "322px" },
              attrs: { id: "old-pie" }
            })
          ]
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "统计说明",
            top: "2vh",
            visible: _vm.dataDialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dataDialogVisible = $event
            }
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticStyle: { "line-height": "25px", "margin-top": "-20px" } },
              [
                _c("div", { staticClass: "data-title" }, [_vm._v("用户统计")]),
                _vm._v(
                  "\n                在住用户=当前在住的房客+同住人+临时授权开门的用户；"
                ),
                _c("br"),
                _vm._v(
                  "\n                已退租用户=当前已退房的房客+已退房的同住人；"
                ),
                _c("br"),
                _vm._v(
                  "\n                分享授权用户=用户来源为分享钥匙+授权临时开门创建的用户；"
                ),
                _c("br"),
                _vm._v(
                  "\n                智家锁用户=当前在使用智家锁的管理员+普通用户（当前已解绑智家锁或被删除授权智家锁的用户不统计在内）；"
                ),
                _c("br"),
                _vm._v(
                  "\n                实名认证用户=云数平台实名认证的用户（不包含已注销的实名认证用户）；"
                ),
                _c("br"),
                _vm._v(
                  "\n                普通用户=有手机号码的非实名认证的用户（不包含已注销的实名认证用户）；"
                ),
                _c("br"),
                _vm._v(
                  "\n                PMS排房用户=用户来源为PMS排房创建的用户；"
                ),
                _c("br"),
                _vm._v(
                  "\n                注销用户=当前已注销用户+申请注销用户；"
                ),
                _c("br")
              ]
            ),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("房东统计")]),
              _vm._v(
                "\n                房东（已审核通过）=已审核通过的房东总数"
              ),
              _c("br"),
              _vm._v(
                "\n                房东比例=已审核通过房东人数/全部房东人数*100%;"
              ),
              _c("br"),
              _vm._v("\n                企业房东=已审核通过的企业房东人数；"),
              _c("br"),
              _vm._v(
                "\n                企业房东比例=已审核通过的企业房东人数/已审核通过的所有房东人数*100%；"
              ),
              _c("br"),
              _vm._v(
                "\n                房东（在用/有租约）=已审核通过的房东人数，且当前有“在租”租约的房东人数；"
              ),
              _c("br"),
              _vm._v(
                "\n                房东（在租/有租约）比例=当前有“在租”租约的房东人数/已审核通过的房东人数*100%；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("用户分布top10")
              ]),
              _vm._v(
                "\n                1.按照用户所在的城市，以城市为单位，统计用户人数，根据城市人数排序，展示前10个城市的数据，其他城市合计展示在最下方。"
              ),
              _c("br"),
              _vm._v(
                "\n                2.云数当前的所有用户，不包含已注销的用户；"
              ),
              _c("br"),
              _vm._v(
                "\n                3.用户未填写或无法获取用户城市的，显示为：未知，统计到“其他”类型中；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("用户画像_性别")
              ]),
              _vm._v("\n                1.按照用户的性别进行统计；"),
              _c("br"),
              _vm._v("\n                2.不包含已注销的用户；"),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("用户增长数据")
              ]),
              _vm._v(
                "\n                1.按年、月、日时间维度，统计用户每年、每月、每日的增长人数；"
              ),
              _c("br"),
              _vm._v("\n                2.按年，从2019年开始，至今；"),
              _c("br"),
              _vm._v(
                "\n                3.按月，从当前月往前展示，水平时间轴可滑动，最多展示最近12个的用户增长情况；"
              ),
              _c("br"),
              _vm._v(
                "\n                4.按日，从今日往前展示，最多展示最近30自然日的用户增长情况；"
              ),
              _c("br"),
              _vm._v(
                "\n                5.纵轴表示人数，可根据时间段内，最高/5，取整，显示；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [_vm._v("在住分析")]),
              _vm._v("\n                1.在住用户与非在住用户的对比分析；"),
              _c("br"),
              _vm._v(
                "\n                2.在住用户：当月有套间的房客用户+同住人用户+临时分享套间的用户；"
              ),
              _c("br"),
              _vm._v(
                "\n                3.非在住用户：当月已退房的房客+已退房的同住人（不包含智家锁用户、柜锁用户、无开门套间和开锁权限的用户）；"
              ),
              _c("br")
            ]),
            _c("div", { staticStyle: { "line-height": "25px" } }, [
              _c("div", { staticClass: "data-title" }, [
                _vm._v("用户画像_年龄")
              ]),
              _vm._v("\n                1.按照用户的年龄段统计用户数量；"),
              _c("br"),
              _vm._v(
                "\n                2.年龄段分为：18岁以下（含18岁），18-35岁（不含18岁，含35岁，以此类推），35-45岁，45-55岁；55岁以上、未知；"
              ),
              _c("br"),
              _vm._v(
                "\n                3.平台所有用户，不包含已注销或申请注销的用户；"
              ),
              _c("br"),
              _vm._v(
                "\n                4.针对年龄未知的用户，增加展示一项未知；"
              ),
              _c("br")
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }