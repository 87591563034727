<template>
    <div class="data-overview">
        <div></div>
        <div class="refresh-box">
            <el-button type="text" class="refresh-btn" @click="dataDialogVisible=true"><i class="el-icon-question" style="margin-right:2px"></i>统计说明</el-button>
            <div style="margin:0 20px">更新时间：{{time}}</div>
            <el-button type="text" class="refresh-btn" @click="refresh()"><i class="el-icon-refresh" style="margin-right:2px"></i>刷新</el-button>
        </div>
        <div class="module">
            <div>
                <div class="module-content" style="margin-bottom: 30px;">
                    <div class="title">用户统计</div>
                    <div class="count-content">
                        <div class="count-num">
                            <div style="font-size:30px;">{{residingUserCount}}</div>
                            <div>在住用户</div>
                        </div>
                        <div class="line"></div>
                        <div class="count-num">
                            <div style="font-size:30px;">{{vacatedUserCount}}</div>
                            <div>已退租用户</div>
                        </div>
                        <div class="line"></div>
                        <div class="count-num">
                            <div style="font-size:30px;">{{sharingAuthorizedUserCount}}</div>
                            <div>分享授权用户</div>
                        </div>
                        <div class="line"></div>
                        <div class="count-num">
                            <div style="font-size:30px;">{{smartLockUserCount}}</div>
                            <div>智家锁用户</div>
                        </div>
                    </div>
                    <div class="line-x"></div>
                    <div class="count-content" style="padding-bottom:10px">
                        <div class="count-num">
                            <div style="font-size:30px;">{{verifiedUserCount}}</div>
                            <div>实名认证用户</div>
                        </div>
                        <div class="line"></div>
                        <div class="count-num">
                            <div style="font-size:30px;">{{ordinaryUserCount}}</div>
                            <div>普通用户(手机号码)</div>
                        </div>
                        <div class="line"></div>
                        <div class="count-num">
                            <div style="font-size:30px;">{{pmsAllocatedUserCount}}</div>
                            <div>PMS排房用户</div>
                        </div>
                        <div class="line"></div>
                        <div class="count-num">
                            <div style="font-size:30px;">{{deactivatedUserCount}}</div>
                            <div>注销用户</div>
                        </div>
                    </div>
                </div>
                <div class="module-content">
                    <div class="title">房东统计</div>
                    <div class="count-content2">
                        <div class="module-count count1">
                            <div style="text-align:left;">
                                <div style="font-size:30px;">{{approvedLandlordCount}}</div>
                                <div>{{approvedLandlordPercent}}%</div>
                                <div>房东(已审核通过)</div>
                            </div>
                            <img style="width:53px;height:51px;margin-top:35px;" src="../../assets/fangdong (1).png" />
                        </div>
                        <div class="module-count count2">
                            <div style="text-align:left;">
                                <div style="font-size:30px;">{{corporateLandlordCount}}</div>
                                <div>{{corporateLandlordPercent}}%</div>
                                <div>企业房东</div>
                            </div>
                            <img style="width:43px;height:44px;margin-top:35px;" src="../../assets/qiyefangdong.png" />
                        </div>
                        <div class="module-count count3">
                            <div style="text-align:left;">
                                <div style="font-size:30px;">{{activeLandlordCount}}</div>
                                <div>{{activeLandlordPercent}}%</div>
                                <div>房东（在用/有租约）</div>
                            </div>
                            <img style="width:60px;height:40px;margin-top:35px;" src="../../assets/ziyongfangdong.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">用户分布top10</div>
                <div id="user-echart" class="module-content" :style="{ width: '784px', height: '322px'}"></div> 
            </div>
        </div>
        <div class="module">
            <div style="background: #FFFFFF;padding: 10px 10px 0 10px;">
                <div class="title" style="margin-bottom:10px">用户画像_性别</div>
                <div id="sex-pie" :style="{ width: '534px', height: '322px'}"></div> 
            </div>
            <div style="background: #FFFFFF;padding: 10px 10px 0 10px;">
                <div class="title" style="margin-bottom:10px">用户增长数据</div>
                <div style="text-align:right;margin-right:56px">
                    <el-button style="height: 30px;line-height: 0px;" @click="changeYear()">按年</el-button>
                    <el-button style="height: 30px;line-height: 0px;" @click="changeMonth()">按月</el-button>
                    <el-button style="height: 30px;line-height: 0px;" @click="changeDay()">按日</el-button>
                </div>
                <div id="line-echart" :style="{ width: '1034px',  height: '322px'}"></div>
            </div>
        </div>
        <div class="module">
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title">在住分析</div>
                <div id="live-echart" :style="{ width: '1004px', height: '322px'}"></div>  
            </div>
            <div style="background: #FFFFFF;padding: 10px 0 0 10px;">
                <div class="title" style="margin-bottom:10px">用户画像_年龄</div>
                <div id="old-pie" :style="{ width: '586px',  height: '322px'}"></div>
            </div>
        </div>
        <el-dialog title="统计说明" top="2vh" :visible.sync="dataDialogVisible"  :close-on-click-modal="false">
            <div>
                <div style="line-height:25px;margin-top:-20px">
                    <div class="data-title">用户统计</div>
                    在住用户=当前在住的房客+同住人+临时授权开门的用户；<br>
                    已退租用户=当前已退房的房客+已退房的同住人；<br>
                    分享授权用户=用户来源为分享钥匙+授权临时开门创建的用户；<br>
                    智家锁用户=当前在使用智家锁的管理员+普通用户（当前已解绑智家锁或被删除授权智家锁的用户不统计在内）；<br>
                    实名认证用户=云数平台实名认证的用户（不包含已注销的实名认证用户）；<br>
                    普通用户=有手机号码的非实名认证的用户（不包含已注销的实名认证用户）；<br>
                    PMS排房用户=用户来源为PMS排房创建的用户；<br>
                    注销用户=当前已注销用户+申请注销用户；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">房东统计</div>
                    房东（已审核通过）=已审核通过的房东总数<br>
                    房东比例=已审核通过房东人数/全部房东人数*100%;<br>
                    企业房东=已审核通过的企业房东人数；<br>
                    企业房东比例=已审核通过的企业房东人数/已审核通过的所有房东人数*100%；<br>
                    房东（在用/有租约）=已审核通过的房东人数，且当前有“在租”租约的房东人数；<br>
                    房东（在租/有租约）比例=当前有“在租”租约的房东人数/已审核通过的房东人数*100%；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">用户分布top10</div>
                    1.按照用户所在的城市，以城市为单位，统计用户人数，根据城市人数排序，展示前10个城市的数据，其他城市合计展示在最下方。<br>
                    2.云数当前的所有用户，不包含已注销的用户；<br>
                    3.用户未填写或无法获取用户城市的，显示为：未知，统计到“其他”类型中；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">用户画像_性别</div>
                    1.按照用户的性别进行统计；<br>
                    2.不包含已注销的用户；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">用户增长数据</div>
                    1.按年、月、日时间维度，统计用户每年、每月、每日的增长人数；<br>
                    2.按年，从2019年开始，至今；<br>
                    3.按月，从当前月往前展示，水平时间轴可滑动，最多展示最近12个的用户增长情况；<br>
                    4.按日，从今日往前展示，最多展示最近30自然日的用户增长情况；<br>
                    5.纵轴表示人数，可根据时间段内，最高/5，取整，显示；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">在住分析</div>
                    1.在住用户与非在住用户的对比分析；<br>
                    2.在住用户：当月有套间的房客用户+同住人用户+临时分享套间的用户；<br>
                    3.非在住用户：当月已退房的房客+已退房的同住人（不包含智家锁用户、柜锁用户、无开门套间和开锁权限的用户）；<br>
                </div>
                <div style="line-height:25px">
                    <div class="data-title">用户画像_年龄</div>
                    1.按照用户的年龄段统计用户数量；<br>
                    2.年龄段分为：18岁以下（含18岁），18-35岁（不含18岁，含35岁，以此类推），35-45岁，45-55岁；55岁以上、未知；<br>
                    3.平台所有用户，不包含已注销或申请注销的用户；<br>
                    4.针对年龄未知的用户，增加展示一项未知；<br>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            time: '',
            residingUserCount: '',
            verifiedUserCount: '',
            vacatedUserCount: '',
            ordinaryUserCount: '',
            sharingAuthorizedUserCount: '',
            pmsAllocatedUserCount: '',
            smartLockUserCount: '',
            deactivatedUserCount: '',
            approvedLandlordCount: '',
            approvedLandlordPercent: '',
            corporateLandlordCount: '',
            corporateLandlordPercent: '',
            activeLandlordCount: '',
            activeLandlordPercent: '',
            cityName: [],
            cityData: [],
            sexPieData: [],
            dataDialogVisible: false,
            dailyXData:[],
            dailyYData:[],
            monthlyXData:[],
            monthlyYData:[],
            yearlyXData:[],
            yearlyYData:[],
            lineXData:[],
            lineYData:[],
            interval:0,
            residencyData:[],
            unResidencyData:[],
            livingXData:[],
            ageStageUserCount:{}
        }
    },
    activated() {
        this.getData();
        this.time = this.getNowTime();
    },
    mounted() {
    },
    methods: {
        getData(){
            this.post("statistic-service/statistic/getUserStatistics").then(res=>{
                if(res){
                    this.residingUserCount = res.residingUserCount!=null?res.residingUserCount:'--';
                    this.vacatedUserCount = res.vacatedUserCount!=null?res.vacatedUserCount:'--';
                    this.sharingAuthorizedUserCount = res.sharingAuthorizedUserCount!=null?res.sharingAuthorizedUserCount:'--';
                    this.pmsAllocatedUserCount = res.pmsAllocatedUserCount!=null?res.pmsAllocatedUserCount:'--';
                    this.smartLockUserCount = res.smartLockUserCount!=null?res.smartLockUserCount:'--';
                    this.deactivatedUserCount = res.deactivatedUserCount!=null?res.deactivatedUserCount:'--';
                    this.approvedLandlordCount = res.approvedLandlordCount!=null?res.approvedLandlordCount:'--';
                    this.approvedLandlordPercent = res.approvedLandlordPercent!=null?res.approvedLandlordPercent:'--';
                    this.corporateLandlordCount = res.corporateLandlordCount!=null?res.corporateLandlordCount:'--';
                    this.corporateLandlordPercent = res.corporateLandlordPercent!=null?res.corporateLandlordPercent:'--';
                    this.activeLandlordCount = res.activeLandlordCount!=null?res.activeLandlordCount:'--';
                    this.activeLandlordPercent = res.activeLandlordPercent!=null?res.activeLandlordPercent:'--';
                    this.maleUserDemographics = res.maleUserDemographics!=null?res.maleUserDemographics:0;
                    this.femaleUserDemographics = res.femaleUserDemographics!=null?res.femaleUserDemographics:0;
                    
                    // 处理用户统计数据
                    let livingX = [];
                    let residency = [];
                    res.residencyUsers.forEach(item=>{
                        livingX.push(item.date);
                        residency.push(item.userCount);
                    })
                    this.residencyData = residency;
                    this.livingXData = livingX;
                    let unResidency = [];
                    res.unResidencyUsers.forEach(item=>{
                        unResidency.push(item.userCount);
                    })
                    this.unResidencyData = unResidency;
                    this.creatLiving();
                }
            })
            this.post("statistic-service/statistic/getUserGrowthStatistics").then(res=>{
                if(res){
                    this.ageStageUserCount = res.ageStageUserCount!=null?res.ageStageUserCount:{};
                    this.creatOld();
                    // 处理折线图数据
                    let monthlyX = [];
                    let monthlyY = [];
                    res.monthlyUserGrowth.forEach(item =>{
                        monthlyX.push(item.date);
                        monthlyY.push(item.userCount)
                    })
                    this.monthlyXData = monthlyX;
                    this.monthlyYData = monthlyY;

                    let dailyX = [];
                    let dailyY = [];
                    res.dailyUserGrowth.forEach(item =>{
                        dailyX.push(item.date.slice(0, 10));
                        dailyY.push(item.userCount)
                    })
                    this.dailyXData = dailyX;
                    this.dailyYData = dailyY;

                    let yearlyX = [];
                    let yearlyY = [];
                    res.yearlyUserGrowth.forEach(item =>{
                        yearlyX.push(item.date);
                        yearlyY.push(item.userCount)
                    })
                    this.yearlyXData = yearlyX;
                    this.yearlyYData = yearlyY;
                    this.lineXData = this.monthlyXData;
                    this.lineYData = this.monthlyYData;
                    this.creatLine();
                }
            })
            this.post("statistic-service/statistic/getUserDemographics").then(res=>{
                if(res){
                    this.ordinaryUserCount = res.ordinaryUserCount!=null?res.ordinaryUserCount:'--';
                    this.verifiedUserCount = res.verifiedUserCount!=null?res.verifiedUserCount:'--';
                    // 处理城市排名
                    let nameData = [];
                    let numberData = [];
                    res.cityUserRank.forEach(item =>{
                        nameData.push(item.city);
                        numberData.push(item.userCount);
                    });
                    this.cityName = nameData;
                    this.cityData = numberData;
                    this.creatUser();

                    // 处理用户性别图表数据
                    let genderData = [];
                    res.userDemographics.forEach(item =>{
                        genderData.push({
                            value:item.userCount,
                            name:item.gender
                        })
                    });
                    this.sexPieData = genderData;
                    this.creatSexPie();
                }
            })
        },
        getNowTime(){
            var date = new Date();
            var sign2 = ":";
            var year = date.getFullYear() // 年
            var month = date.getMonth() + 1; // 月
            var day = date.getDate(); // 日
            var hour = date.getHours(); // 时
            var minutes = date.getMinutes(); // 分
            var seconds = date.getSeconds() //秒
            var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
            var week = weekArr[date.getDay()];
            // 给一位数的数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (day >= 0 && day <= 9) {
                day = "0" + day;
            }
            if (hour >= 0 && hour <= 9) {
                hour = "0" + hour;
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = "0" + minutes;
            }
            if (seconds >= 0 && seconds <= 9) {
                seconds = "0" + seconds;
            }
            return year + "/" + month + "/" + day + " " + hour + sign2 + minutes + sign2 + seconds;
        },
        refresh(){
            this.time = this.getNowTime();
            this.post("statistic-service/statistic/updateResidencyUsers").then(res=>{
                if(res){
                    this.getData() 
                }
            })
        },
        creatSexPie(){
            const chartDom = document.getElementById('sex-pie')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: 'item'
                },
                color: ['#5AC8FA','#FFC600','#69CABE'],
                legend: {
                    orient: 'vertical',
                    right: 'left',
                    itemWidth: 12,   // 设置图例图形的宽
                    itemHeight: 12,  // 设置图例图形的高
                    formatter: function (name) {
                        let data = option.series[0].data
                        let total = 0
                        let tarValue
                        for (let i = 0; i < data.length; i++) {
                        total += data[i].value
                        if (data[i].name == name) {
                            tarValue = data[i].value
                        }
                        }
                        let v = tarValue
                        let p = ((tarValue / total) * 100).toFixed(2) //占比
                        return `${name}  ${v}  ${p}%`
                    },
                },
                series: [
                    {
                        name: '性别',
                        type: 'pie',
                        radius: '50%',
                        data: this.sexPieData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ],
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatLine(){
            const chartDom = document.getElementById('line-echart')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                xAxis: {
                    type: 'category',
                    data: this.lineXData,
                    axisLabel: {
                        interval: this.interval  //设置 X 轴数据间隔几个显示一个，为0表示都显示 
                    }, 
                },
                yAxis: {
                    type: 'value'
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [
                    {
                        data: this.lineYData,
                        type: 'line',
                        symbol: 'circle', // 实心圆点
                        smooth: 0.5, // 设置折线弧度
                    }
                ],
                color: ['#16EA76']
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        changeYear(){
            this.lineXData = this.yearlyXData;
            this.lineYData = this.yearlyYData;
            this.interval = 0;
            this.creatLine()
        },
        changeMonth(){
            this.lineXData = this.monthlyXData;
            this.lineYData = this.monthlyYData;
            this.interval = 0;
            this.creatLine();
        },
        changeDay(){
            this.lineXData = this.dailyXData;
            this.lineYData = this.dailyYData;
            this.interval = 2;
            this.creatLine();
        },
        creatLiving(){
            const chartDom = document.getElementById('live-echart')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                legend: { // 图例
                    show: true,
                    right: 16,
                },
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    data: this.livingXData,
                },
                yAxis: {
                    type: 'value'
                },
                color: [],
                series: [
                    {
                        name: '在住用户',
                        type: 'bar',
                        data: this.residencyData,
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#FFC600' },
                                { offset: 1, color: '#FFF66B' }
                            ]),
                            barBorderRadius: [10, 10, 10, 10],
                        },
                    },
                    {
                        name: '非在住用户',
                        type: 'bar',
                        data: this.unResidencyData,
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#7D83FF' },
                                { offset: 1, color: '#38A8F6' }
                            ]),
                            barBorderRadius: [10, 10, 10, 10],
                        },
                    },
                    
                ],
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatOld(){
            const chartDom = document.getElementById('old-pie')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    left: '5%',
                    left: 'center',
                    itemWidth: 12,   // 设置图例图形的宽
                    itemHeight: 12,  // 设置图例图形的高
                },
                color: ['#629AFF', '#F49A7E', '#9C8EFE', '#69CABE', '#FEE355','#5AC8FA'],
                series: [
                    {
                        name: '用户画像',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        data: [
                            { value: this.ageStageUserCount.below18, name: '18岁以下' },
                            { value: this.ageStageUserCount.age18to35, name: '18-35岁' },
                            { value: this.ageStageUserCount.age36to45, name: '35-45岁' },
                            { value: this.ageStageUserCount.age46to55, name: '45-55岁' },
                            { value: this.ageStageUserCount.above55, name: '55岁以上' },
                            { value: this.ageStageUserCount.other, name: '其他' },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        },
        creatUser(){
            const chartDom = document.getElementById('user-echart')
            const myChart=this.$echarts.init(chartDom)
            var option
            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                //图表位置
                grid: {
                    left: "3%",
                    // right: "4%",
                    bottom: "3%",
                    top:'15px',
                    containLabel: true,
                },
                //X轴
                xAxis: {
                    type: "value",
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //不显示X轴刻度线和数字
                    splitLine: { show: false },
                    axisLabel: { show: false },
                },
                yAxis: {
                    type: "category",
                    data: this.cityName,
                    //升序
                    inverse: true,
                    splitLine: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    //key和图间距
                    offset: 10,
                    //key文字大小
                    nameTextStyle: {
                        fontSize: 16,
                    },
                },
                color: ['#76DC71'],
                series: [
                {
                    //柱状图自动排序，排序自动让Y轴名字跟着数据动
                    realtimeSort: false,
                    name: "数量",
                    type: "bar",
                    data: this.cityData,
                    barWidth: 12,
                    barGap: 20,
                    smooth: false,
                    valueAnimation: false,
                    //Y轴数字显示部分
                    label: {
                        normal: {
                            show: true,
                            position: "right",
                            valueAnimation: false,
                            offset: [5, 2],
                            textStyle: {
                                color: "#333",
                                fontSize: 14,
                            },
                        },
                    },
                    itemStyle: {
                        emphasis: {
                            barBorderRadius: 7,
                        },
                        //颜色样式部分
                        normal: {
                            barBorderRadius: 7,
                        },
                    },
                },
                ],
            };
            // 图表大小跟随窗口变化
            const chartObserver = new ResizeObserver(() => {
                myChart.resize()
            })
            chartObserver.observe(chartDom)
            option && myChart.setOption(option)
        }
    }
}
</script>
<style lang="scss" scope>
.data-overview{
    background: #F6F6F6;
    margin: -10px -20px;
    padding: 35px;
    font-size: 14px;
    overflow: auto;
    .module{
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
    }
    .refresh-box{
        height: 16px;
        line-height: 16px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        margin-right: 20px;
    }
    .refresh-btn{
        color: #333;
        padding: 0;
        margin-left: 3px;
    }
    .title{
        border-left: 4px solid #30BAC1;
        height: 16px;
        line-height: 16px;
        padding-left: 7px;
        background: #FFFFFF;
        font-size: 16px;
    }
    .count-content{
        background: #FFFFFF;
        width: 794px;
        height: 90px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
    }
    .count-content2{
        background: #FFFFFF;
        width: 794px;
        height: 182px;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        text-align: center;
    }
    .line{
        background: #DCDFE6;
        width: 1px;
        height: 93px;
    }
    .module-content {
        padding: 10px 0 0 10px;
        background: #FFFFFF;
    }
    .data-title {
        font-weight: bold;
        margin: 10px 0 0;
    }
    .count-num{
        text-align: center;
        width: 198px;
    }
    .line-x{
        background: #DCDFE6;
        width: 794px;
        height: 1px;
    }
    .module-count{
        color: #fff;
        width: 210px;
        height: 82px;
        padding: 10px 15px;
        border-radius: 10px;
        display: flex; 
        justify-content: space-between;
    }
    .count1{
        background-image: linear-gradient(to right , #5671FC, #C97DFE);
    }
    .count2{
        background-image: linear-gradient(to right , #38A8F6, #767CFC);
    }
    .count3{
        background-image: linear-gradient(to right , #48D898, #9EE04E);
    }
}
</style>